@font-face {
    font-family: 'Harietta';
    font-weight: 400;
    font-style: normal;
    src: local('Harietta'), url('Regular/HVHariettaRegular.ttf') format('truetype'), url('/styles/fonts/Harietta/Regular/HVHariettaRegular.otf') format('otf'),
    url('/styles/fonts/Harietta/Regular/HVHariettaRegular.woff') format('woff'), url('/styles/fonts/Harietta/Regular/HVHariettaRegular.woff2') format('woff2');
}
@font-face {
    font-family: 'Harietta';
    font-weight: 700;
    font-style: normal;
    src: local('Harietta'), url('Bold/HVHariettaBold.ttf') format('truetype'), url('/styles/fonts/Harietta/Bold/HVHariettaBold.otf') format('otf'),
    url('/styles/fonts/Harietta/Bold/HVHariettaBold.woff') format('woff'), url('/styles/fonts/Harietta/Bold/HVHariettaBold.woff2') format('woff2');
}