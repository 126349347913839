html {
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

html::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
}

html::-webkit-scrollbar-thumb {
    background: transparent;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.no-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
}

.no-scrollbar {
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
}

.message-list-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.step-content-active {
    animation-name: fadeIn;
    animation-duration: 300ms;
    animation-timing-function: linear;
}

.colored-shadow {
    box-shadow: 0px 10px 25px -10px rgba(24, 154, 180, 0.22);
}

.react-datepicker {
    border: 1px solid #189ab4 !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    visibility: hidden !important;
}

.react-datepicker-popper {
    z-index: 31 !important;
}

[dir="rtl"] .react-datepicker__close-icon {
    left: 10px !important;
    right: unset !important;
    top: 7px !important;
    color: #189ab4 !important;
}

[dir="ltr"] .react-datepicker__close-icon {
    right: 10px !important;
    left: unset !important;
    top: 7px !important;
    color: #189ab4 !important;
}

.react-datepicker__close-icon::after {
    background-color: #189ab4 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #189ab4 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #189ab4 !important;
}

.react-datepicker__header {
    text-align: center;
    background-color: white !important;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}

.mapboxgl-ctrl-bottom-left {
    display: none !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

* {
    outline: none;
    box-shadow: none;
}

.pac-container {
    /* padding: 15px; */
    border-radius: 10px;
    border: 1px solid #189ab4;
}

.pac-icon {
    display: none;
}

.pac-item {
    padding: 0 15px;
    border: none;
    font-size: 14px;
    transition: all 0.3s ease;
}

.pac-item:hover {
    cursor: pointer;
    background-color: rgba(24, 154, 180, .1);
}
